const initialState = {
    id: 0,
    campaignName: '',
    content: [],
    chapter: [],
    plan: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SET_PLAN':
            console.log(action.payload)
            return {
                ...state,
                plan: action.payload
            }
        case 'SET_CONTENT':
            return {
                ...state,
                content: action.payload
            }
        case 'SET_CHAPTER':
            return {
                ...state,
                chapter: action.payload
            }
        case 'SET_CAMPAIGN_NAME':
            return {
                ...state,
                campaignName : action.payload
            }
        default: return state
    }
}