import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import IntegrationHead from "./IntegrationHead";
import ConnectFacebook from "./ConnectFacebook";
import ConnectYoutube from "./ConnectYoutube";
import ConnectReelapps from "./ConnectReelapps";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import Webinar from "../dashboard/Webinar";
import {useSelector} from "react-redux";
import sonorityIcon from "../../images/logo-sonority.png";
import TitleBar from "../TitleBar";

const Integration = () => {

    const socialData = useSelector(state => state.social);
    return(
        <>
            <TitleBar/>
            <Navbar/>

            <div className="siteWrap">
                <IntegrationHead socialData={socialData} />

                <section className="connection">
                    <div className="container">
                    <h2 className="titleBdr">My Connection</h2>

                        <ConnectFacebook data={socialData.facebook}/>
                        <ConnectYoutube data={socialData.youtube} />
                        <ConnectReelapps icon={syvidIcon} name="Syvid" type="syvid" data={socialData.syvid} />
                        <ConnectReelapps icon={scriptIcon} name="ScriptReel" type="scriptreel" data={socialData.scriptreel} />
                        <ConnectReelapps icon={trimIcon} name="TrimReel" type="trimreel" data={socialData.trimreel} />
                        <ConnectReelapps icon={liveIcon} name="LiveReel" type="livereel" data={socialData.livereel} />
                        <ConnectReelapps icon={sonorityIcon} name="Sonority" type="sonority" data={socialData.sonority} />

                    </div>
                </section>

                <Webinar/>
            </div>

            <Footer/>
        </>
    )
}

export default Integration;