import React, {useEffect, useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ContentList from "../project/ContentList";
import ChapterList from "../project/ChapterList";
import planIcon from "../../images/plan.png";
import contentIcon from "../../images/content.png";
import chaptersIcon from "../../images/chapters.png";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import {useDispatch } from "react-redux";
import { fetchPlan, fetchChapter, fetchContent,addCampaingName } from "../../actions/courseAction";
const CreateCourse = () => {
    const [campName, setCampName] = useState('')
    const dispatch = useDispatch()

    useEffect(()=>{
        // dispatch(fetchPlan())
        // dispatch(fetchContent())
        // dispatch(fetchChapter())
    },[])

    return (
        <>
            <TitleBar/>
            <Navbar/>

            <section className="siteWrap">
                <div className="create-course-top">
                   <div className="container">
                       <div className="row justify-content-center">
                           <div className="col-lg-8">
                               <h2>Create <span>Course</span></h2>
                           </div>
                       </div>
                   </div>
                </div>
                <div className="create-course-mid">
                    <div className="container">
                       <div className="row justify-content-center">
                           <div className="col-xl-8">
                               <div className="course-mid-main">
                                   <h2>Add Course</h2>
                                   <input 
                                   className="courseInp" 
                                   placeholder="Give a name to your course  (Required)" 
                                   onChange={(e) => setCampName(e.target.value)}/>
                               </div>
                               <div className="course-step">
                                   <ul>
                                       <li>
                                           <Link to={'/plan'}>
                                               <div className="course-step-img"><img src={planIcon} /></div>
                                               <p>Plan</p>
                                               <div className="course-step-icon"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                                           </Link>
                                       </li>
                                       <li>
                                           <Link to={'/content-research'}>
                                               <div className="course-step-img"><img src={contentIcon} /></div>
                                               <p>Content</p>
                                               <div className="course-step-icon"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                                           </Link>
                                       </li>
                                       <li>
                                           <Link to={'/editor'}>
                                               <div className="course-step-img"><img src={chaptersIcon} /></div>
                                               <p>Chapters</p>
                                               <div className="course-step-icon"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                                           </Link>
                                       </li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>

                <div className="container">

                    <div className="videoProject">
                        <div className="videoProject-top">
                            <h2>Content</h2>
                        </div>
                        <div className="videoProject-bottom">
                            <div className="row">
                                <ContentList />
                            </div>
                        </div>
                    </div>

                    <div className="videoProject alt">
                        <div className="videoProject-top">
                            <h2>Chapters</h2>
                        </div>
                        <div className="videoProject-bottom">
                            <div className="row">
                                <ChapterList />
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <Footer/>
        </>
    )
}

export default CreateCourse;