import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import {Helmet} from "react-helmet";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";


const Dashboard = () => {
    return(
        <>
            <TitleBar/>
            <Navbar/>

            <section className="siteWrap">
                <DashboardHead/>
                <DashboardContent/>
                <DashboardAbout/>
            </section>
            
            <Webinar/>
            <Footer/>
        </>
    )
}

export default Dashboard;