import React from "react";

const UpgradesMenu = () => {
    return (
        <>
                    <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a className="nav-link" id="v-pills-wlrebranding-tab" data-toggle="pill"
                           href="#v-pills-wlrebranding" role="tab" aria-controls="v-pills-wlrebranding"
                           aria-selected="false">Agency Rebranding</a>
                        <a className="nav-link" id="v-pills-business-tab" data-toggle="pill" href="#v-pills-business"
                           role="tab" aria-controls="v-pills-business" aria-selected="false">Business Finder</a>
                        <a className="nav-link" id="v-pills-fbtemplate-tab" data-toggle="pill"
                           href="#v-pills-fbtemplate" role="tab" aria-controls="v-pills-fbtemplate"
                           aria-selected="true">FB Templates</a>
                        {/* <a className="nav-link" id="v-pills-vidoeflix-tab" data-toggle="pill" href="#v-pills-vidoeflix"
                           role="tab" aria-controls="v-pills-vidoeflix" aria-selected="false">Video Flix</a> */}
                        <a className="nav-link" id="v-pills-udamy-tab" data-toggle="pill" href="#v-pills-udamy"
                           role="tab" aria-controls="v-pills-udamy" aria-selected="false">Acadeable</a>
                        {/* <a className="nav-link" id="v-pills-dfycourse-tab" data-toggle="pill" href="#v-pills-dfycourse"
                           role="tab" aria-controls="v-pills-dfycourse" aria-selected="false">DFY Course</a> */}
                        <a className="nav-link" id="v-pills-client-tab" data-toggle="pill" href="#v-pills-client"
                           role="tab" aria-controls="v-pills-client" aria-selected="false">Client Contract</a>
                        <a className="nav-link" id="v-pills-reseller-tab" data-toggle="pill" href="#v-pills-reseller"
                           role="tab" aria-controls="v-pills-reseller" aria-selected="false">Reseller License</a>
                        <a className="nav-link" id="v-pills-webhosting-tab" data-toggle="pill"
                           href="#v-pills-webhosting" role="tab" aria-controls="v-pills-webhosting"
                           aria-selected="false">Web Hosting</a>
                        <a className="nav-link" id="v-pills-videocommercial-tab" data-toggle="pill"
                           href="#v-pills-videocommercial" role="tab" aria-controls="v-pills-videocommercial"
                           aria-selected="false">Video Commercial</a>
                        <a className="nav-link" id="v-pills-videopromo-tab" data-toggle="pill"
                           href="#v-pills-videopromo" role="tab" aria-controls="v-pills-videopromo"
                           aria-selected="false">Video Promos</a>
                        <a className="nav-link" id="v-pills-dfylead-tab" data-toggle="pill" href="#v-pills-dfylead"
                           role="tab" aria-controls="v-pills-dfylead" aria-selected="false">DFY Lead Magnets</a>
                        <a className="nav-link" id="v-pills-agency-tab" data-toggle="pill" href="#v-pills-agency"
                           role="tab" aria-controls="v-pills-agency" aria-selected="false">Agency Website</a>
                        <a className="nav-link" id="v-pills-social-tab" data-toggle="pill" href="#v-pills-social"
                           role="tab" aria-controls="v-pills-social" aria-selected="false">Social Media Assets</a>
                        <a className="nav-link" id="v-pills-dfyarticles-tab" data-toggle="pill"
                           href="#v-pills-dfyarticles" role="tab" aria-controls="v-pills-dfyarticles"
                           aria-selected="false">DFY Articles</a>
        
                    </div>
        </>
    )
}

export default UpgradesMenu;