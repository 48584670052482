import React from "react";
import ReelAppsLogo from '../images/ReelApps.png';
import {useSelector} from "react-redux";

const Footer = ( ) => {

    const rebrandData = useSelector(state => state.rebrand);

    return(
        <>
            <footer className="appFooter innerPage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-2">
                            <div className="footer-single">
                                <ul>
                                    <li><a href="https://coursereel.io/terms.html" target="_blank">TERMS</a></li>
                                    <li><a href="https://coursereel.io/privacy.html" target="_blank">PRIVACY</a></li>
                                    <li><a href="http://support.vineasx.com/" target="_blank">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="footer-single">

                                <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank">Vega6</a></p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-single reelLogo">
                                {
                                    rebrandData.data !== false ?
                                        <a>
                                            <img src={rebrandData.data.logo} className="img-fluid ml-auto" alt="logo"  width="180px" />
                                        </a> :
                                        <a href="https://reelapps.io/" target="_blank"><img src={ReelAppsLogo} alt={'logo'} /></a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default React.memo(Footer);