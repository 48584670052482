import React, { useEffect, useState } from 'react';
import Navbar from "../Navbar";
import Footer from "../Footer";
import planIcon from "../../images/plan.png";
import contImg from "../../images/cont-img.png";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import '../../css/plan.css'
import { useDispatch, useSelector } from 'react-redux';
import { addPlan } from '../../actions/courseAction';
import { setAlert } from '../../actions/alert';
import Alert from '../Alert';

const Plan = () => {

    const [nodeId, setNodeId] = useState(1)
    const [skip, setSkip] = useState(false);

    const [color, setColor] = useState('#ffffff')
    const [sizeFont, setSizeFont] = useState(17)

    const [selectedNodeKeys, setSelectedNodeKeys] = useState([0])
    const [selectedLinkKeys, setSelectedLinkKeys] = useState([])

    const dispatch = useDispatch()
    const plan = useSelector(state => state.course.plan)
    
    const [model, setModel] = useState({
        nodeDataArray: [],
        nodeLinkArray: []
    })

   

    useEffect(()=>{
        if(plan){
            setModel(plan)
            const id = plan.nodeDataArray.length
            setNodeId(id)
        }
    },[])

    useEffect(() => {
        if (model.nodeDataArray.length === 0 && !plan) {
            setModel({
                ...model,
                nodeDataArray: model.nodeDataArray.concat({ key: 0, label: "Parent", bgColor: '#9708e9', fontSize: '17px sarif' })
            })
        }
    }, []);

    const handleSave = () => {
        console.log(model)
        if(model.nodeDataArray.length > 1){
            dispatch(addPlan(model))
        }
        else(
            dispatch(setAlert("Please Make Plan first", 'danger'))
        )
    }

    const initDiagram = () => {
        const $ = go.GraphObject.make;
        const diagram =
            $(go.Diagram,
                {
                    'undoManager.isEnabled': true,
                    // initialContentAlignment: go.Spot.LeftCenter,
                    model: new go.GraphLinksModel(
                        {
                            linkKeyProperty: 'key',
                        }),

                    layout: $(go.TreeLayout, {
                        angle: 0,
                        arrangement: go.TreeLayout.ArrangementVertical,
                        treeStyle: go.TreeLayout.StyleLayered,

                    }),
                    isReadOnly: false,
                    allowHorizontalScroll: true,
                    allowVerticalScroll: true,
                    allowZoom: true,
                    allowSelect: true,
                    // autoScale: Diagram.Uniform,
                    contentAlignment: go.Spot.LeftCenter,
                    "InitialLayoutCompleted": e => {
                        var node = null;
                        setTimeout(() => focusOnNode(node),
                        );
                    }
                }
            );

        function focusOnNode(node) {
            if (!node) {
                var arr = diagram.model.nodeDataArray;
                var data = arr[0];
                node = diagram.findNodeForData(data);
            }
            if (!node) return;
            diagram.select(node);
        }

        diagram.nodeTemplate =
            $(go.Node, 'Auto',
                {
                    selectionChanged: node => nodeSelectionHandler(node.key, node.isSelected),
                    selectionAdornmentTemplate:
                        $(go.Adornment, "Auto",
                            $(go.Shape, "RoundedRectangle",
                                { fill: null, stroke: "white", strokeWidth: 2 }),
                            $(go.Placeholder)
                        ),
                    resizable: true,
                },
                $(go.Shape, 'RoundedRectangle',
                    { name: 'SHAPE', fill: 'white', strokeWidth: 0 },
                    new go.Binding('fill', 'bgColor')),
                $(go.TextBlock,
                    { margin: 8, editable: true, width: 80, textAlign: 'center', font: '20px sarif' },
                    new go.Binding('text', 'label').makeTwoWay(),
                    new go.Binding('font', 'fontSize').makeTwoWay()
                )
            );
        diagram.linkTemplate =
            $(go.Link,
                {
                    selectionChanged: link => linkSelectionHandler(link.key, link.isSelected),
                },
                $(go.Shape, { strokeWidth: 1.5, stroke: 'white' },
                    new go.Binding("stroke", "color")),
                $(go.Shape, { toArrow: "OpenTriangle", strokeWidth: 1.5, stroke: 'white' },
                    new go.Binding("stroke", "color"))
            );
        return diagram;
    }

    const myCanvas = () => {
        return [
            <ReactDiagram
                key={"goJsDiagram"}
                initDiagram={initDiagram}
                divClassName='myDiagram'
                nodeDataArray={model.nodeDataArray}
                linkDataArray={model.nodeLinkArray}
                onModelChange={handleModelChange}
                skipsDiagramUpdate={skip}
            />
        ]
    }

    //Node Selection

    const nodeSelectionHandler = (nodeKey, isSelected) => {
        selectedNodeKeys.pop()
        if (isSelected) {
            setSelectedNodeKeys(selectedNodeKeys.concat(nodeKey))
        }
        else {
            setSelectedNodeKeys([0])
        }
    }

    const linkSelectionHandler = (link, isSelected) => {
        if (isSelected) {
            setSelectedLinkKeys(selectedLinkKeys.concat(link))
        }
    }

    const handleFontSize = (e) => {
        setSkip(false)
        setSizeFont(e.target.value)
        const updateFontSize = model.nodeDataArray.map((node) => {
            if (node.key === selectedNodeKeys[0]) {
                return {
                    ...node,
                    fontSize: `${e.target.value}px sarif`
                }
            }
            else {
                return node
            }
        })
        setModel({
            ...model,
            nodeDataArray: updateFontSize
        })
    }

    //Chnage Node and Link Color
    const handleColorChange = (e) => {
        setSkip(false)
        setColor(e.target.value)
        const updateNodeColor = model.nodeDataArray.map((node) => {
            if (node.key === selectedNodeKeys[0]) {
                return {
                    ...node,
                    bgColor: e.target.value
                }
            }
            else {
                return node
            }
        })
        const updateLinkColor = model.nodeLinkArray.map((link) => {
            if (link.key === selectedLinkKeys[0]) {
                return {
                    ...link,
                    color: e.target.value
                }
            }
            else {
                return link
            }
        })
        setModel(
            {
                ...model,
                nodeDataArray: updateNodeColor,
                nodeLinkArray: updateLinkColor
            })
    }
    //Add Nodes 

    const addNode = () => {
        setSkip(false)
        setNodeId(nodeId + 1)
        const newNodeId = nodeId
        const linksToAdd = selectedNodeKeys.map(parent => {
            return { key: newNodeId, from: parent, to: newNodeId, color: color }
        })
        setModel({
            ...model,
            nodeDataArray: [
                ...model.nodeDataArray,
                { key: newNodeId, label: `Child ${newNodeId}`, bgColor: color, fontSize: `${sizeFont}px sarif` }
            ],
            nodeLinkArray:
                linksToAdd.length > 0 ?
                    [...model.nodeLinkArray].concat(linksToAdd)
                    : [...model.nodeLinkArray]
        })
    }
    //Node Handler Fucncions

    function handleModelChange(data) {
        setSkip(true)
        //Deleting Node
        if (data.removedNodeKeys) {
            if (data.removedNodeKeys[0] === 0) {
                return
            }
            const nodeKey = data.removedNodeKeys[0]
            let linkArr = [...model.nodeLinkArray]
            const nodeToUpdateIndex = model.nodeDataArray.findIndex(node => node.key === nodeKey)
            if (nodeToUpdateIndex === -1) {
                return
            }
            //Deleting Link

            if (data.removedLinkKeys) {
                for (var i = 0; i < linkArr.length; i++) {
                    if (nodeKey === linkArr[i].key || nodeKey === linkArr[i].from || nodeKey === linkArr[i].to) {
                        linkArr = [...linkArr.slice(0, i), ...linkArr.slice(i + 1)]
                        i = 0
                    }
                }
            }
            setModel(
                {
                    ...model,
                    nodeDataArray: [
                        ...model.nodeDataArray.slice(0, nodeToUpdateIndex),
                        ...model.nodeDataArray.slice(nodeToUpdateIndex + 1)
                    ],
                    nodeLinkArray: linkArr
                })
        }
        //Data Modification

        if (data.modifiedNodeData && !data.modelData) {
            const nodeKey = data.modifiedNodeData[0].key
            const nodeToUpdateIndex = model.nodeDataArray.findIndex(node => node.key === nodeKey)
            if (nodeToUpdateIndex === -1) {
                return
            }
            setModel(
                {
                    ...model,
                    nodeDataArray: [
                        ...model.nodeDataArray.slice(0, nodeToUpdateIndex),
                        data.modifiedNodeData[0],
                        ...model.nodeDataArray.slice(nodeToUpdateIndex + 1)
                    ]
                })
        }
    }

    const handleRefresh = () => {
        setSkip(false)
        setModel({
            ...model,
            nodeDataArray: [{ key: 0, label: "Parent", bgColor: '#9708e9', sizeFont: '17px sarif' }],
            nodeLinkArray: []
        })
        setNodeId(1)
    }

    return (
        <>
            <TitleBar />
            <Navbar />
            <Alert />
            <section className="siteWrap"> 
                <div className="container">
                    <div className="cont-research">
                        <div className="cont-research-head">
                            <div className="research-head-left">
                                <h2>Create your Mind Map</h2>
                            </div>
                            <div className="back-Button">
                                <Link to={'/create-course'}>
                                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    Back
                                </Link>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 cont-research-btn'>
                                <button type="button" className='btn btn-primary m-2' onClick={addNode}>Add Node</button>
                                <button type="button" className="btn btn-primary m-2" onClick={handleRefresh}>Reset</button>
                                <button type="button" className='btn btn-primary m-2' onClick={handleSave}>Save</button>
                            </div>
                            <div className='col-md-6 text-right gojs-color-picker'>
                                <div className='font-adjust'>
                                   <div>
                                   <label className="form-label" htmlFor="font"> Font size </label>
                                   <input type={"number"} name="font" className="gojs-node-font-size" value={sizeFont} onChange={handleFontSize} min={1} />
                                   </div>
                                   <div>
                                   <label className="form-label" htmlFor="color"> Colors </label>
                                   <input type={"color"} name="color" className="picker" value={color} onChange={handleColorChange} />
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            {myCanvas()}
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Plan;