import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";


export const addPlan = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: '',
        data: data,
        headers: { 'Content_Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"))
            dispatch(fetchPlan())
        }
    }).catch(error => {
        console.log(error)
    })
}

export const fetchPlan = () => (dispatch) => {
    axios({
        method: 'POST',
        url: '',
        data: {},
        headers: { 'Content_Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({
                type: 'SET_PLAN',
                payload: res.data.data
            })
        }
    }).catch(error => {
        console.log(error)
    })
}

export const fetchContent = () => (dispatch) => {
    axios({
        method: 'POST',
        url: ``,
        data: '',
        headers: { 'Content_Type': 'application/json' }
    }).then(res => {
        dispatch({
            type: 'SET_CONTENT',
            payload: res.data
        })
    }).catch(error => {
        console.log(error)
    })
}
export const addContent = () => (dispatch) => {
    axios({
        method: 'POST',
        url: '',
        data: '',
        headers: { 'Content_Type': 'application/json' }
    }).then(res => {
        dispatch(setAlert(res.data.message, "success"))
        dispatch(fetchContent())
    }).catch(error => {
        console.log(error)
    })
}
export const fetchChapter = () => (dispatch) => {
    axios({
        method: 'POST',
        url: '',
        data: '',
        headers: { 'Content_Type': 'application/json' }
    }).then(res => {
        dispatch({
            type: 'SET_CHAPTER',
            payload: res.data
        })
    }).catch(error => {
        console.log(error)
    })
}

export const addChapter = () => (dispatch) => {
    axios({
        method: 'POST',
        url: '',
        data: '',
        headers: { 'Content_Type': 'application/json' }
    }).then(res => {
        dispatch(setAlert(res.data.message, "success"))
        dispatch(fetchChapter())
    }).catch(error => {
        console.log(error)
    })
}

export const addCampaingName = (name) => (dispatch) => {
    dispatch({
        type: 'SET_CAMPAIGN_NAME',
        payload: name
    })
}

