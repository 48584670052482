import React from "react";
import Navbar from "../Navbar";
import VideoCard from "./VideoCard";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

const Projects = () => {
    return(
        <>
            <TitleBar/>
            <Navbar/>

            <section className="siteWrap">

                <div className="projectTitle-wrap">
                    <div className="container">
                        <div className="projectTitle">
                            <div className="projectTitle-left">
                                <h2>ALL PROJECTS | <span>1 Project</span> </h2>
                            </div>
                            <div className="projectTitle-right">
                                <div className="project-input-box">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fas fa-search"></i></span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search Projects" />
                                    </div>
                                </div>
                                <button className="demoLink"><i className="fas fa-plus-circle"></i> Create New Project</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">

                    <div className="forproject">
                        <div className="row mt-5 mb-5">
                            <VideoCard/>
                        </div>
                    </div>

                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Projects;