import React, {useState} from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import facebookIcon from "../../images/logo-facebook.svg"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {fetchSocialAccounts, saveFacebook} from "../../actions/socialAction";
import Alert from "../Alert";
import SweetAlert from "react-bootstrap-sweetalert";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";


const ConnectFacebook = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);

    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user.id
    })

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const deleteIntegration = (id) => {
        setIntData({...intData, id: id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton('Deleting...');
        deleteSocialAccounts();
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-social-network`,
            data: intData,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err=>{
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const [groups, setGroups] = useState([]);

    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = async () => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        await dispatch(saveFacebook(data));
        setDetails({...details, openPopup: false});
        setLoader(false);
    };

    const responseFacebook = async (response) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );

            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /** Fetch FB Groups */
        try {
            const res = await axios.get(
                " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );
            console.log(res);

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    return(
        <>
            <Alert/>
            <div className="connection-box mt-3" id="facebook">
                <div className="row">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                            <img src={facebookIcon} width="56px" alt="facebook" />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            props.data.length > 0 ?
                                props.data.map((item,index)=>{
                                    return(
                                        <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>Facebook</h6>
                                                <p className="m-0">{item?.username} - Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button type="button" onClick={(e)=>deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>Facebook</h6>
                                        <p className="m-0">Not Connected</p>
                                    </div>
                                </div>
                        }

                        <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                            <FacebookLogin
                                appId="780210132546000" //APP ID NOT CREATED YET
                                fields="name,email"
                                scope="pages_manage_posts,publish_to_groups,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                callback={(e)=>responseFacebook(e)}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${details.openPopup ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{maxWidth: "500px", margin: "auto"}}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Connect Facebook
                            </h5>
                            <button onClick={(e)=>closeConnectModel()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" style={{backgroundColor: "transparent"}}>
                                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChange}
                                        value={dropValue}>
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </form>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                    <div className="add-account-save-btn">
                                        <button type="submit" className="btn-change7" onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}>
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Connect
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete facebook account
            </SweetAlert>
        </>
    )
}

export default ConnectFacebook;