import React from "react";
import reelapps from "../../images/ReelApps.png";
import ReelAppsLogo from '../../images/ReelApps.png';

const LoginFooter = () => {
    return(
        <>
            <footer className="appFooter">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-2">
                            <div className="footer-single">
                                <ul>
                                    <li><a href="http://clipsreel.io/terms.html" target="_blank">TERMS</a></li>
                                    <li><a href="http://clipsreel.io/privacy.html" target="_blank">PRIVACY</a></li>
                                    <li><a href="http://support.vineasx.com/" target="_blank">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="footer-single">
                            <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank">Vega6</a></p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-single reelLogo">
                                <a href="https://reelapps.io/" target="_blank"><img src={ReelAppsLogo} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default LoginFooter;