import React from "react";

import group2 from "../../images/Group 2.png"
import videop from "../../images/videop.png"
import group24 from "../../images/Group 24.png"
import maskGroup6 from "../../images/Mask Group 6.png";
import maskGroup from "../../images/Mask Group.png"
import VideoCard from "../project/VideoCard";
import {Link} from "react-router-dom";

const DashboardContent = () => {
    return (
        <>

                <section className="videoProject">
                   <div className="container">
                       <div className="videoProject-top">
                           <h2>Video <span>Projects</span></h2>
                           <a href="" className="proView">View all <span><i class="fa fa-angle-right" aria-hidden="true"></i></span> </a>
                       </div>
                       <div className="videoProject-bottom">
                           <div className="row">
                              <VideoCard/>
                           </div>
                       </div>
                   </div>
                </section>
        </>
)
}

export default DashboardContent;