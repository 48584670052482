import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

import contImg from "../../images/cont-img.png";
import TitleBar from "../TitleBar";
import { useSelector } from "react-redux";

const ContentResearch = () => {
    const [searchKey, setSearchKey] = useState('');
    const content = useSelector(state => state.course.content)

    return (
        <>
            <TitleBar />
            <Navbar />

            <section className="siteWrap">
                <div className="container">
                    <div className="cont-research">
                        <div className="cont-research-head">
                            <div className="research-head-left">
                                <h2>Create your Content Research</h2>
                            </div>
                            <div className="research-head-right">
                                <a className="backBtn"> <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</a>
                                <input
                                    className="roundInp"
                                    type="text"
                                    placeholder="Enter Content Name"
                                    value={searchKey}
                                    onChange={e => setSearchKey(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="cont-research-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="research-body-left">
                                        <div className="research-block research-block-top">
                                            <div className="research-block-top-left">
                                                <div className="search-icon">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                    <input className="" type="text" placeholder="Search" />
                                                </div>
                                            </div>
                                            <div className="research-block-top-right">
                                                <div className="search-icon">
                                                    <select>
                                                        <option>Youtube</option>
                                                        <option>FaceBook</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="research-block cont-scroll">
                                            {/* Search Campaign Here */}
                                            {content.length > 0 ?
                                                content.filter((curElem) => {
                                                    if (searchKey === "") {
                                                        return curElem
                                                    }
                                                    else if (curElem.title.toLowerCase().includes(searchKey.toLowerCase())) {
                                                        return curElem
                                                    }
                                                }).map((val) => {
                                                    return (
                                                        <>

                                                            <div className="cont-scroll-block">
                                                                <div className="scroll-block-top">
                                                                    <div>
                                                                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                                                                        <h3><a href="">https://www.google.co.in/</a></h3>
                                                                    </div>
                                                                    <div>
                                                                        <label class="custom-check">
                                                                            <input type="checkbox" />
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="scroll-block-content">
                                                                    <div className="scroll-block-content-img"><img src={contImg} /></div>
                                                                    <div className="scroll-block-content-txt">
                                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="scroll-block-content-bottom">
                                                                    <p>eque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                                                </div> */}
                                                            </div>
                                                        </>

                                                    )
                                                })
                                                : ''
                                            }


                                            <div className="cont-scroll-block">
                                                <div className="scroll-block-top">
                                                    <div>
                                                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                                                        <h3><a href="">https://www.google.co.in/</a></h3>
                                                    </div>
                                                    <div>
                                                        <label class="custom-check">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="scroll-block-content">
                                                    <div className="scroll-block-content-img"><img src={contImg} /></div>
                                                    <div className="scroll-block-content-txt">
                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                                                    </div>
                                                </div>
                                                {/* <div className="scroll-block-content-bottom">
                                                    <p>eque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                                </div> */}
                                            </div>

                                            <div className="cont-scroll-block">
                                                <div className="scroll-block-top">
                                                    <div>
                                                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                                                        <h3><a href="">https://www.google.co.in/</a></h3>
                                                    </div>
                                                    <div>
                                                        <label class="custom-check">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="scroll-block-content">
                                                    <div className="scroll-block-content-img"><img src={contImg} /></div>
                                                    <div className="scroll-block-content-txt">
                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                                                    </div>
                                                </div>
                                                {/* <div className="scroll-block-content-bottom">
                                                    <p>eque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="research-body-right">
                                        <div className="research-block">
                                            <div className="txtEdit">
                                                <div className="txtEdit-top">
                                                    <div className="txtEdit-nav">
                                                        <ul>
                                                            <li>B</li>
                                                            <li>I</li>
                                                            <li>U</li>
                                                            <li>X<sup>1</sup></li>
                                                            <li>X<sup>2</sup></li>
                                                        </ul>
                                                    </div>
                                                    <div className="txtEdit-nav">
                                                        <ul>
                                                            <li><i class="fa fa-align-left" aria-hidden="true"></i></li>
                                                            <li><i class="fa fa-align-center" aria-hidden="true"></i></li>
                                                            <li><i class="fa fa-align-right" aria-hidden="true"></i></li>
                                                            <li><i class="fa fa-align-justify" aria-hidden="true"></i></li>
                                                        </ul>
                                                    </div>
                                                    <div className="txtEdit-nav">
                                                        <ul>
                                                            <li>
                                                                <select>
                                                                    <option>Paragraph</option>
                                                                </select>
                                                            </li>
                                                            <li>
                                                                <select>
                                                                    <option>Font Family</option>
                                                                </select>
                                                            </li>
                                                            <li>
                                                                <select>
                                                                    <option>Font Size</option>
                                                                </select>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="txtEdit-main">
                                                    <textarea placeholder="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt"></textarea>
                                                </div>
                                                <div className="txtEdit-foot">
                                                    <p className="text-right">Words: 72</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ContentResearch;