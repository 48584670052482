import React from "react";
import {Link} from "react-router-dom";
import questionLogo from '../../images/question.png';

const HelpSubContent = (props) => {
    console.log(props)
    return(
        <>
            <div className="col-xl-3 col-lg-4 col-sm-6">
                <Link to={props.url}>
                <div className="impTopic-single">
                    <div className="text-center">
                        <img src={props.image} alt="union" />
                        {props.name}
                        <h3>{props.name}</h3>
                    </div>
                </div>
                </Link>
            </div>
        </>
    )
}

export default HelpSubContent;