import React from 'react';
import videop from "../../images/videop.png";
import group24 from "../../images/Group 24.png";
import videoThumb from '../../images/video.png';
import { useSelector } from 'react-redux';

const ContentList = () => {
    const content = useSelector(state => state.course.content)
    console.log(content)
    return (

        <>

            {content.length > 0 ?
                content.map((curElem, index) => {
                    return (
                        <div className="col-xl-3 col-sm-6" key={index}>

                            <div className="videoProject-single">
                                <div className="videoProject-single-top">
                                    <div className="videoProject-single-vid">
                                        <img className="vidThumb" src={curElem.url} />
                                    </div>
                                    <div className="vidHover">
                                        <div><p>April 28, 2021  5:8:44</p></div>
                                        <div className="vidiconAll">

                                            <div className="vidHover-single">
                                                <a href="">
                                                    <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                            <div className="vidHover-single">
                                                <a href="">
                                                    <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                    <span>Delete</span>
                                                </a>
                                            </div>
                                            <div className="vidHover-single">
                                                <a href="">
                                                    <span className="vidHover-icon"><i class="fa fa-download" aria-hidden="true"></i></span>
                                                    <span>Download</span>
                                                </a>
                                            </div>
                                            <div className="vidHover-single">
                                                <a href="">
                                                    <span className="vidHover-icon"><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                    <span>Publish</span>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="videoProject-single-bottom">
                                    <div className="playBtn"><i class="fa fa-play" aria-hidden="true"></i></div>
                                    <div className="videoProject-single-bottom-desc">
                                        April 28, 2021  5:8:44 <br />
                                        <span>About Water</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                : ''
            }


            <div className="col-xl-3 col-sm-6">

                <div className="videoProject-single">
                    <div className="videoProject-single-top">
                        <div className="videoProject-single-vid">
                            <img className="vidThumb" src={videoThumb} />
                        </div>
                        <div className="vidHover">
                            <div><p>April 28, 2021  5:8:44</p></div>
                            <div className="vidiconAll">

                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                        <span>Edit</span>
                                    </a>
                                </div>
                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                        <span>Delete</span>
                                    </a>
                                </div>
                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-download" aria-hidden="true"></i></span>
                                        <span>Download</span>
                                    </a>
                                </div>
                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                        <span>Publish</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="videoProject-single-bottom">
                        <div className="playBtn"><i class="fa fa-play" aria-hidden="true"></i></div>
                        <div className="videoProject-single-bottom-desc">
                            April 28, 2021  5:8:44 <br />
                            <span>About Water</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6" >

                <div className="videoProject-single">
                    <div className="videoProject-single-top">
                        <div className="videoProject-single-vid">
                            <img className="vidThumb" src={videoThumb} />
                        </div>
                        <div className="vidHover">
                            <div><p>April 28, 2021  5:8:44</p></div>
                            <div className="vidiconAll">

                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                        <span>Edit</span>
                                    </a>
                                </div>
                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                        <span>Delete</span>
                                    </a>
                                </div>
                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-download" aria-hidden="true"></i></span>
                                        <span>Download</span>
                                    </a>
                                </div>
                                <div className="vidHover-single">
                                    <a href="">
                                        <span className="vidHover-icon"><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                        <span>Publish</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="videoProject-single-bottom">
                        <div className="playBtn"><i class="fa fa-play" aria-hidden="true"></i></div>
                        <div className="videoProject-single-bottom-desc">
                            April 28, 2021  5:8:44 <br />
                            <span>About Water</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContentList;
