import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

import iconSave from "../../images/icon-save.svg";
import iconrender from "../../images/icon-render.svg";

import iconStory from "../../images/icon-story.svg";
import iconText from "../../images/icon-text.svg";
import iconMedia from "../../images/icon-media.svg";
import iconAudio from "../../images/icon-audio.svg";
import iconSettings from "../../images/icon-settings.svg";
import iconCredits from "../../images/icon-credits.svg";
import iconMotion from "../../images/icon-motion.svg";
import iconTransition from "../../images/icon-transition.svg";

import noimg from "../../images/no-img.jpg";
import addReelIcon from "../../images/add-reel.svg";
import waveIcon from "../../images/wave.svg";
import prevIcon from "../../images/icon-preview.svg";

import logoImg from "../../images/IMG.png";

import { Tab, Nav, Row, Col } from "react-bootstrap";
import TitleBar from "../TitleBar";

import { FiTrash2 } from "react-icons/fi";
import { FiRotateCcw } from "react-icons/fi";

const Editor = () => {
    return (
        <>
            <TitleBar/>
            <Navbar/>

            <section className="siteWrap">
                <div className="editorWrap">
                <Tab.Container id="left-tabs-example" defaultActiveKey="story">

                    <div className="editor-left">
                        <div>
                        <Tab.Content>

                        <Tab.Pane eventKey="story">
                          <div className="tabInner">
                            <div className="story-block">
                              <ul>
                                <li className="active">
                                  <div className="story-block-single">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="story-block-single">
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="story-block-single">
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="story-block-single">
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="story-block-single">
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="text">
                          <div className="tabInner">
                            <div className="txt-format">
                              <h2>Font Style</h2>
                              <div className="fontStyle">
                                <div className="fontStyle-block">
                                  <div className="row">
                                    <div className="col-8">
                                      <select>
                                        <option>Roboto</option>
                                        <option>Roboto</option>
                                      </select>
                                    </div>
                                    <div className="col-4">
                                      <select>
                                        <option>24</option>
                                        <option>25</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="fontStyle-block">
                                  <div className="row">
                                    <div className="col-2">
                                      <div className="font-format"><span className="font-weight-bold">T</span></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><span className="font-italic">T</span></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><span>TT</span></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><span>Tt</span></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><span className="text-decoration-underline">T</span></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><span className="text-decoration-line-through">T</span></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="fontStyle-block">
                                  <div className="row">
                                    <div className="col-2">
                                      <div className="font-format"><i class="fa fa-align-left" aria-hidden="true"></i></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><i class="fa fa-align-center" aria-hidden="true"></i></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><i class="fa fa-align-right" aria-hidden="true"></i></div>
                                    </div>
                                    <div className="col-2">
                                      <div className="font-format"><i class="fa fa-align-justify" aria-hidden="true"></i></div>
                                    </div>
                                    <div className="col-4">
                                      <div className="font-format"><span>T</span></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="fontStyle-block-wrap">
                                 <h2>Font Color</h2>
                                 <div className="fontStyle-block">
                                   <div className="row">
                                     <div className="col-6">
                                       <div className="inpWrap">
                                          <input className="pl-5" type="text" value="#FFF" />
                                          <span className="colorValue"></span>
                                          <span className="label">HEX</span>
                                       </div>
                                     </div>
                                     <div className="col-2">
                                       <div className="inpWrap">
                                          <input className="text-center" type="text" value="255" />
                                          <span className="label">R</span>
                                       </div>
                                     </div>
                                     <div className="col-2">
                                       <div className="inpWrap">
                                          <input className="text-center" type="text" value="255" />
                                          <span className="label">G</span>
                                       </div>
                                     </div>
                                     <div className="col-2">
                                       <div className="inpWrap">
                                          <input className="text-center" type="text" value="255" />
                                          <span className="label">B</span>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                                 <div className="color-palette">
                                   <ul>
                                     <li><div style={{background: "#FFF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#E1E1E1"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#D7D7D7"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#CCCCCC"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#C2C2C2"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#B7B7B7"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#A1A1A1"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#959595"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#E8C9FF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#C87EFF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#F34AFF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#D19DF8"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#9B33E9"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#8133DB"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#7033D5"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#23803F"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#54C423"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#606888"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#7C83AC"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#5B69BE"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#30409B"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#223CDB"}} className="color-palette-single"></div></li>
                                   </ul>
                                 </div>
                              </div>

                              <div className="fontStyle-block-wrap">
                                 <h2>Font Background</h2>
                                 <div className="fontStyle-block">
                                   <div className="row">
                                     <div className="col-6">
                                       <div className="inpWrap">
                                          <input className="pl-5" type="text" value="#FFF" />
                                          <span className="colorValue"></span>
                                          <span className="label">HEX</span>
                                       </div>
                                     </div>
                                     <div className="col-2">
                                       <div className="inpWrap">
                                          <input className="text-center" type="text" value="255" />
                                          <span className="label">R</span>
                                       </div>
                                     </div>
                                     <div className="col-2">
                                       <div className="inpWrap">
                                          <input className="text-center" type="text" value="255" />
                                          <span className="label">G</span>
                                       </div>
                                     </div>
                                     <div className="col-2">
                                       <div className="inpWrap">
                                          <input className="text-center" type="text" value="255" />
                                          <span className="label">B</span>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                                 <div className="color-palette">
                                   <ul>
                                     <li><div style={{background: "#FFF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#E1E1E1"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#D7D7D7"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#CCCCCC"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#C2C2C2"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#B7B7B7"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#A1A1A1"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#959595"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#E8C9FF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#C87EFF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#F34AFF"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#D19DF8"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#9B33E9"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#8133DB"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#7033D5"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#23803F"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#54C423"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#606888"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#7C83AC"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#5B69BE"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#30409B"}} className="color-palette-single"></div></li>
                                     <li><div style={{background: "#223CDB"}} className="color-palette-single"></div></li>
                                   </ul>
                                 </div>
                              </div>

                              <div className="fontStyle-block-wrap">
                                <div className="itemDisp">
                                  <div className="itemDisp-left">
                                  Apply to all slides
                                  </div>
                                  <div className="itemDisp-right">
                                    <div className="switch-single">
                                        <label class="switch">
                                        <input type="checkbox"/>
                                        <span class="slider round"></span>
                                        </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="media">
                          <div className="tabInner">
                            <div className="media-block">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                                  <Nav variant="pills" className="inTabNav">
                                    <Nav.Item>
                                      <Nav.Link eventKey="image-tab">Images</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="library-tab">Library</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="content-tab">Content Library</Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                
                                  <Tab.Content>
                                    <Tab.Pane eventKey="image-tab">
                                      <div className="innertab">
                                        <div className="tabSearch">
                                          <input type="text" placeholder="Search" />
                                          <span className="inp-icon"><i class="fa fa-search" aria-hidden="true"></i></span>
                                        </div>
                                        <div className="innertab-scroll">
                                          <div className="mediaList">
                                            <ul>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="library-tab">
                                    <div className="innertab">
                                        <div className="tabSearch">
                                          <input type="text" placeholder="Search" />
                                          <span className="inp-icon"><i class="fa fa-search" aria-hidden="true"></i></span>
                                        </div>
                                        <div className="innertab-scroll">
                                          <div className="mediaList">
                                            <ul>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="upload-tab">
                                    <div className="innertab">
                                        <div className="tabSearch">
                                          <button className="demoLink btn-block mt-0"><i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Media</button>
                                          <input className="absInp" type="file" />
                                        </div>
                                        <div className="innertab-scroll">
                                          <div className="mediaList">
                                            <ul>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="content-tab">
                                    <div className="innertab">
                                        <div className="tabSearch">
                                          <input type="text" placeholder="Search" />
                                          <span className="inp-icon"><i class="fa fa-search" aria-hidden="true"></i></span>
                                        </div>
                                        <div className="innertab-scroll">
                                          <div className="mediaList">
                                            <ul>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                              <li>
                                                <div className="mediaList-single">
                                                  <img className="mediaImg" src={noimg} />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                            </Tab.Container>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="audio">
                          <div className="tabInner">
                            <div className="media-block">
                            <Tab.Container id="left-tabs-example-2" defaultActiveKey="music-tab">
                                <Nav variant="pills" className="inTabNav full">
                                  <Nav.Item>
                                    <Nav.Link eventKey="music-tab">Music</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="voice-tab">AI Voices</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="upload-audio">Upload Audio</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              
                                <Tab.Content>
                                  <Tab.Pane eventKey="music-tab">
                                    <div className="innertab">
                                      <div className="innertab-scroll alt">
                                        <div className="mediaList">
                                          <ul>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="voice-tab">
                                    <div className="innertab">
                                      <div className="innertab-scroll alt">
                                        <div className="ai-voice-block">
                                          <div className="voice-block-top">
                                            <div className="row">
                                              <div className="col-3">
                                                <select>
                                                  <option>1</option>
                                                  <option>2</option>
                                                </select>
                                                <span className="optDesc">SLIDE</span>
                                              </div>
                                              <div className="col-4">
                                                <select>
                                                  <option>English, US</option>
                                                  <option>English, US</option>
                                                </select>
                                                <span className="optDesc">LANGUAGE</span>
                                              </div>
                                              <div className="col-5">
                                                <select>
                                                  <option>Salli: female</option>
                                                  <option>Salli: female</option>
                                                </select>
                                                <span className="optDesc">VOICE</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="voice-block-mid">
                                            <textarea placeholder="Add text here"></textarea>
                                          </div>
                                          <div className="voice-block-bottom">
                                            <div className="row">
                                              <div className="col-6">
                                                <button className="demoLink btn-block"><img src={prevIcon} /> Preview</button>
                                              </div>
                                              <div className="col-6">
                                                <button className="demoLink btn-block"><img src={iconSave} /> Save & Use</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="upload-audio">
                                  <div className="innertab">
                                      <div className="tabSearch">
                                        <button className="demoLink btn-block mt-0"><i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Audio</button>
                                        <input className="absInp" type="file" />
                                      </div>
                                      <div className="innertab-scroll">
                                        <div className="mediaList">
                                          <ul>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="audioList-single">
                                                <div className="audioList-top">
                                                  <img className="waveIcon" src={waveIcon} />
                                                  <span className="audio-time">3:00</span>
                                                </div>
                                                <div className="audioList-bottom">
                                                   <i class="fa fa-play-circle" aria-hidden="true"></i>
                                                   <p>Sitting on the moon</p>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              
                           </Tab.Container>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="settings">
                          <div className="tabInner">
                            <div className="media-block">

                               {/* <div className="add-block-single">
                                 <h2>Add Logo</h2>
                                 <div className="add-block-main">
                                    <div className="fileUpload">
                                      <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Your Logo
                                      <input type="file" />
                                    </div> 
                                    <div className="itemDisp">
                                      <div className="itemDisp-left">
                                        <ul>
                                          <li>
                                            
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="itemDisp-right">
                                        <div className="switch-single">
                                          <span className="switch-title">Remove Logo</span>
                                           <label class="switch">
                                            <input type="checkbox"/>
                                            <span class="slider round"></span>
                                           </label>
                                        </div>
                                      </div>
                                    </div>
                                 </div>
                               </div> */}

                                <div className="add-block-single">
                                 <div className="add-block-main">
                                  <div className="add-block-title  mb-4">
                                      <h2>Add Logo</h2>
                                      <div className="switch-single">
                                          <label class="switch">
                                          <input type="checkbox"/>
                                          <span class="slider round"></span>
                                          </label>
                                      </div>
                                    </div>
                                    <div className="fileUpload">
                                      <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Your Logo
                                      <input type="file" />
                                    </div> 
                                    <div className="itemDisp">
                                      <div className="itemDisp-left">
                                        <ul>
                                          <li>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="itemDisp-right">
                                        <select>
                                          <option>Top Left</option>
                                          <option>Top Mid</option>
                                          <option>Top Right</option>
                                          <option>Bottom Left</option>
                                          <option>Bottom Mid</option>
                                          <option>Bottom Right</option>
                                        </select>
                                      </div>
                                    </div>
                                 </div>
                               </div>

                               <div className="add-block-single">
                                 <div className="add-block-main">
                                    <div className="add-block-title  mb-4">
                                       <h2>Add Watermark</h2>
                                       <div className="switch-single">
                                          <label class="switch">
                                          <input type="checkbox"/>
                                          <span class="slider round"></span>
                                          </label>
                                       </div>
                                    </div>
                                    <div className="fileUpload">
                                      <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Your Watermark
                                      <input type="file" />
                                    </div> 
                                    <div className="itemDisp">
                                      <div className="itemDisp-left">
                                        <ul>
                                          <li>
                                            <img className="uploadImg" src={logoImg} />
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="itemDisp-right">
                                        <select>
                                          <option>Top Left</option>
                                          <option>Top Mid</option>
                                          <option>Top Right</option>
                                          <option>Bottom Left</option>
                                          <option>Bottom Mid</option>
                                          <option>Bottom Right</option>
                                        </select>
                                      </div>
                                    </div>
                                 </div>
                               </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="credits">
                        <div className="tabInner">
                            <div className="media-block">

                               <div className="add-block-single">
                                 <div className="add-block-main">
                                  <div className="add-block-title  mb-4">
                                      <h2>Intro</h2>
                                      <div className="switch-single">
                                          <label class="switch">
                                          <input type="checkbox"/>
                                          <span class="slider round"></span>
                                          </label>
                                      </div>
                                    </div>
                                    <div className="fileUpload">
                                      <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Your Intro
                                      <input type="file" />
                                    </div> 
                                    <div className="itemDisp">
                                      <div className="itemDisp-left">
                                        <ul>
                                          <li>
                                            
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="itemDisp-right">
                                      <a href="">Select Intro from library +</a>
                                      </div>
                                    </div>
                                 </div>
                               </div>

                               <div className="add-block-single">
                                 <div className="add-block-main">
                                 <div className="add-block-title mb-4">
                                    <h2>Outro</h2>
                                    <div className="switch-single">
                                          <label class="switch">
                                          <input type="checkbox"/>
                                          <span class="slider round"></span>
                                          </label>
                                    </div>
                                 </div>
                                    <div className="fileUpload">
                                      <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Your Outro
                                      <input type="file" />
                                    </div> 
                                    <div className="itemDisp">
                                      <div className="itemDisp-left">
                                        <ul>
                                          <li>
                                            <img className="uploadImg" src={logoImg} />
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="itemDisp-right">
                                         <a href="">Select Outro from library +</a>
                                      </div>
                                    </div>
                                 </div>
                               </div>

                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="motion">
                          <div className="tabInner">
                            <div className="media-block">
                               <div className="add-block-single">
                                 <h2>Animation / Motion</h2>
                                 <div className="add-block-main">
                                   <h3>Attention Seekers</h3>
                                   <div className="motionlist">
                                     <ul>
                                       <li>
                                         <div className="motionlist-single active">Bounce</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Flash</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Pulse</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Rubber Band</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Swing</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Heart Beat</div>
                                       </li>
                                     </ul>
                                   </div>
                                 </div>
                               </div>
                               <div className="add-block-single">
                                 <div className="add-block-main">
                                   <h3>Shake</h3>
                                   <div className="motionlist">
                                     <ul>
                                       <li>
                                         <div className="motionlist-single">Shake X</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Shake Y</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Head Shake</div>
                                       </li>
                                     </ul>
                                   </div>
                                 </div>
                               </div>
                               <div className="add-block-single">
                                 <div className="add-block-main">
                                   <h3>Back In</h3>
                                   <div className="motionlist">
                                     <ul>
                                       <li>
                                         <div className="motionlist-single">Back in_down</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Back in_left</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Back in_right</div>
                                       </li>
                                       <li>
                                         <div className="motionlist-single">Back in_up</div>
                                       </li>
                                     </ul>
                                   </div>
                                 </div>
                               </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="transition">
                          <div className="tabInner">
                            <div className="media-block">
                               <div className="add-block-single">
                                 <h2>Transition</h2>
                                 <div className="add-block-main">
                                   <div className="motionlist">
                                     <ul>
                                       <li>
                                         <div className="transition-single">
                                            <img className="transition-img" src={logoImg} />
                                            <div className="transition-txt">Angular</div>
                                         </div>
                                       </li>
                                       <li>
                                         <div className="transition-single">
                                            <img className="transition-img" src={logoImg} />
                                            <div className="transition-txt">Angular</div>
                                         </div>
                                       </li>
                                       <li>
                                         <div className="transition-single">
                                            <img className="transition-img" src={logoImg} />
                                            <div className="transition-txt">Angular</div>
                                         </div>
                                       </li>
                                       <li>
                                         <div className="transition-single">
                                            <img className="transition-img" src={logoImg} />
                                            <div className="transition-txt">Angular</div>
                                         </div>
                                       </li>
                                     </ul>
                                   </div>
                                 </div>
                               </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        </Tab.Content>
                        </div>
                    </div>
                    <div className="editor-right">
                      <div className="campaignTop">
                        <div className="campaignBar">
                            <input type="text" className="roundInp" placeholder="Add Campaign Name" />
                            <button className="demoLink"><img src={iconSave} /> Save Project</button>
                            <button className="demoLink"><img src={iconrender} /> Render</button>
                        </div>
                        <div className="campaignNav">
                        <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="story">
                                <div>
                                    <span><img src={iconStory} /></span>
                                    <span>Story</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="text">
                                <div>
                                    <span><img src={iconText} /></span>
                                    <span>Text</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="media">
                                <div>
                                    <span><img src={iconMedia} /></span>
                                    <span>Media</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="audio">
                                <div>
                                    <span><img src={iconAudio} /></span>
                                    <span>Audio</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="settings">
                                <div>
                                    <span><img src={iconSettings} /></span>
                                    <span>Settings</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="credits">
                                <div>
                                    <span><img src={iconCredits} /></span>
                                    <span>Credits</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="motion">
                                <div>
                                    <span><img src={iconMotion} /></span>
                                    <span>Motion</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="transition">
                                <div>
                                    <span><img src={iconTransition} /></span>
                                    <span>Transition</span>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </div>
                        </div>

                        <div className="canvasEditor">
                            <div className="canvasEditor-main">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/klyiX9CdXIA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className="videoControll">
                              <div className="row">
                                <div className="col-4">
                                  <div className="videoControll-txt">
                                    <span>00:00:00</span>
                                    <span>00:01:00</span>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="videoControll-nav">
                                    <ul>
                                      <li><i class="fa fa-step-backward" aria-hidden="true"></i></li>
                                      <li><i class="fa fa-play" aria-hidden="true"></i></li>
                                      <li><i class="fa fa-step-forward" aria-hidden="true"></i></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="videoControll-right">
                                    <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>

                    </div>

                </Tab.Container>
                </div>
            </section>

            <div className="editorReel">
              <div className="editorReelwrap">
                <div className="editorReel-scroll">
                    <ul>
                        <li>
                            <div className="editorReel-single">
                                <div className="delicon">
                                   <FiRotateCcw />
                                   <FiTrash2 />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="editorReel-single">
                            <div className="delicon">
                                   <FiRotateCcw />
                                   <FiTrash2 />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="editorReel-single">
                            <div className="delicon">
                                   <FiRotateCcw />
                                   <FiTrash2 />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="editorReel-single">
                            <div className="delicon">
                                   <FiRotateCcw />
                                   <FiTrash2 />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="addReelBt"><img src={addReelIcon} /></div>
                </div>
                </div>
            </div>

            {/* <Footer/> */}
        </>
    )
}

export default Editor;