import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {updatePassword} from "../../actions/authAction";

const ProfilePassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    });

    const onInputChange = (e) => {
        setUserDetails({...userDetails, [e.target.name] : e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(updatePassword(userDetails, setLoader));
    }

    return(
        <>


        <div className="profileBlock">
           <h2 className="text-center">Reset Password</h2>
            <form className="" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                <div className="form-group form-group-alt">
                    <label className="form-text text-muted">Current Password</label>
                    <input type="password" className="form-control"
                            placeholder="**********" name="password" required
                            onChange={(e)=>onInputChange(e)}
                    />
                </div>

                <div className="row">
                    <div className="col-lg-6">
                    <div className="form-group form-group-alt">
                        <label className="form-text text-muted">New Password</label>
                        <input type="password" className="form-control"
                                placeholder="**********" name="new_password" required
                                onChange={(e)=>onInputChange(e)}
                        />
                    </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group form-group-alt">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control"
                                    placeholder="**********" name="confirm_password" required
                                    onChange={(e)=>onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>
                    
                <button type="submit" className="fbtn btn-block demoLink" disabled={loader}> { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update</button>

            </form>
        </div>
        </>
    )
}

export default ProfilePassword;