import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
// import logo from "../images/LOGO.png"
import bellIcon from "../images/BELL ICON.png"
import navBg from "../images/Navbg.png";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../actions/authAction";

import { Dropdown } from 'react-bootstrap';

import logo from '../../src/images/logo.svg';
import noProfileImage from '../../src/images/no-profile.png';

const Navbar = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand); 

    const [rebrand, setRebrand] = useState(rebrandData);

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
      };

    const logout = () => {
        dispatch(logoutUser());
    }

    useEffect(()=>{
        setRebrand(rebrandData);
    },[rebrandData])

    return (
        <>

        <header className="appHeader">
           <div className="container">
               <div className="appHeader-main">
                   <div className="logo">
                      <Link to={'/'}><img src={rebrandData.data !== false ? rebrandData.data.logo : logo} /></Link>
                   </div>
                   <div className="navExpand" onClick={handleToggle}><i class="fa fa-bars" aria-hidden="true"></i></div>
                   <div className={`appHeader-right ${!isActive ? "act" : ""}`}>
                       <div className="appHeader-nav">
                           <ul>
                               <li><NavLink className="nav-link" to="/dashboard" activeClassName="active">Dashboard</NavLink></li>
                               <li><NavLink className="nav-link" to="/projects" activeClassName="active">Projects</NavLink></li>
                               {
                                    auth.user.is_client_account == "0" ?
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/integration">Integrations</NavLink>
                                        </li>
                                    :''
                                }
                               <li className="nav-item"><NavLink className="nav-link" to="/help-and-support">Help</NavLink></li>
                           </ul>
                       </div>
                       <div className="appHeader-action">

                           <div className="action-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic2">
                                        <div className="dropProfileImage"><img src={noProfileImage} /></div>
                                        <div>
                                            <span className="welcometxt">Welcome</span> 
                                            <span className="profileName">{auth.user?.name}</span> 
                                        </div>  
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-dark">
                                    {
                                        auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                            <>
                                                <Link className="dropdown-item" to="/profile">
                                                    <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                                </Link>
                                                <Link className="dropdown-item" to="/privacy">
                                                    <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                </Link>
                                                {
                                                    auth.user.is_reseller_account === "0" ?
                                                        <>
                                                            <Link className="dropdown-item" to="account-management">
                                                                <span className="mr-2"><i className="fas fa-users" ></i></span>
                                                                Account Management
                                                            </Link>
                                                        </>
                                                    :''
                                                }
                                            </>
                                        : ''
                                    }
                                    {
                                        auth.user.is_client_account == "0" ?
                                            <Link className="dropdown-item" to="/upgrades">
                                                <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                            </Link>
                                        : ''
                                    }
                                    <Link className="dropdown-item" to="/help-and-support">
                                        <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                    </Link>

                                    {/* <Link className="dropdown-item" to="/content-research">
                                        <span className="mr-2"><i class="fa fa-file-text-o" aria-hidden="true"></i></span> Content Research
                                    </Link> */}

                                    {/* <Link className="dropdown-item" to="/editor">
                                        <span className="mr-2"><i class="fa fa-cube" aria-hidden="true"></i></span> Editor
                                    </Link> */}

                                    <span className="dropdown-divider"></span>
                                    <Link className="dropdown-item" onClick={(e)=>logout()}>
                                        <span className="mr-2">
                                            <i className="fas fa-sign-out-alt"></i>
                                        </span> Sign Out
                                    </Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </header>
        </>
)
}


export default React.memo(Navbar);