import React from "react";
import PrivacyEmail from "./PrivacyEmail";
import PrivacyGdpr from "./PrivacyGdpr";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

const Privacy = () => {
    return (
        <>
            <TitleBar/>
            <Navbar/>

            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                            <PrivacyEmail/>
                            <PrivacyGdpr/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Privacy;