import React from "react";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import {useSelector} from "react-redux";
import queryString from "query-string";

const Training = ({location}) => {

    const brandName = useSelector(state => state.rebrand.data);

    const supportData = useSelector(state => state.support.articles);
    const articleId = queryString.parse(location.search).id;

    return(
        <>
            <TitleBar/>
            <Navbar/>

            <section className="siteWrap">
                <div className="tabCont">
                    <div className="row">

                        <TrainingTitles location={location} id={articleId} supportData={supportData} name={false} />
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Training;