import React, {useEffect, useState} from "react";
import icon from "../../images/ICON.png"
import icon2 from "../../images/ICON2.png"
import icon3 from "../../images/ICON3.png"
import group56 from "../../images/Group 56.png"
import image3 from "../../images/Mask-Group-3.png"
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {addScrapText} from "../../actions/videoAction";

import { Modal } from 'react-bootstrap';


import iconUplod from '../../images/icon-upload.svg';
import iconWrite from '../../images/icon-write.svg';
import iconMic from '../../images/icon-mic.svg';
import iconRight from '../../images/icon-arrow.svg';
import modalCloseIcon from '../../images/modal-close.png';

const DashboardHead = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);
    const [frameUrl, setFrameUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [blogUrl, setBlogUrl] = useState('');
    const [eComUrl, setEcomUrl] = useState('');

    const [blog, setBlog] = useState(false);
    const [ecom, setEcom] = useState(false);

    const showBlog = () => {
        setBlog(true);
    }

    const showEcom = () => {
        setEcom(true);
    }


    const addFrameUrl = () => {
        setShowModal(true);
        setFrameUrl('https://player.vimeo.com/video/373325714');
    }

    const closePopUp = () => {
        setFrameUrl('');
        setShowModal(false);
    }

    const onFormSubmit = (e,type) => {
        e.preventDefault();
        setLoader(true);

        let url = 'https://node.clipsreel.io/scrap?url='+blogUrl;
        if(type === 'ecom'){
            url = 'https://node.clipsreel.io/scrap?url='+eComUrl
        }

        axios({
            method: 'GET',
            url: url,
            headers: {'Content-Type': 'application/json'}
        }).then(res=>{
            console.log(res);
            if(res.status === 200){
                dispatch(addScrapText(res.data));
                history.push(`add-script?type=${type}`);
            }
            setBlog(false);
            setEcom(false);
            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    useEffect(()=>{
        setRebrand(rebrandData);
    },[rebrandData])


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>

            <section className="hero-banner">
                <div className="hero-banner-txt">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="hero-banner-left">
                                    <h2>WELCOME TO</h2>
                                    <h3>TO COURSEREEL 2.0</h3>
                                    <p>Start converting your voice-over or audio recordings into full content videos. Watch how easy it is for anyone to create great videos by converting audio or video recordings into a fresh new look using AI.</p>
                                    <a onClick={handleShow} className="demoLink"><i class="fa fa-play" aria-hidden="true"></i> Watch Demo Video</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hero-banner-right">
                                    <Link to="/create-course" className="bannerLink">
                                        <div className="bannerLink-left"><img src={iconWrite} />Create Course</div>
                                        <div className="bannerLink-right"><img src={iconRight} /></div>
                                    </Link>
                                    <Link to="/projects" data-toggle="modal" data-target="#MyBlog" className="bannerLink">
                                        <div className="bannerLink-left"><img src={iconUplod} />View Course</div>
                                        <div className="bannerLink-right"><img src={iconRight} /></div>
                                    </Link>
                                    <Link to={'/integration'}  className="bannerLink">
                                        <div className="bannerLink-left"><img src={iconMic} />Integrations</div>
                                        <div className="bannerLink-right"><img src={iconRight} /></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={`modal VideoModal ${blog ? 'show-modal' : ''}`} >
                <div className="modal-dialog modal-dialog-centered  ecommercebox " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">Blog Post</h5>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">

                                <form className="form-group text-center" method="post" onSubmit={(e)=>onFormSubmit(e,'blog')}>

                                    <div className="formCont">
                                    <input type="url" onChange={(e)=>setBlogUrl(e.target.value)} className="form-control" placeholder={`Enter Blog Post URL`} />
                                    </div>

                                    <div className="groupbtn">
                                        <button type="button" onClick={(e)=>setBlog(false)} className="demoLink" disabled={loader}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="demoLink" disabled={loader}>
                                            { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Create
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/klyiX9CdXIA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <div className={`modal VideoModal ${ecom ? 'show-modal' : ''}`} >
                <div className="modal-dialog modal-dialog-centered  ecommercebox " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">E-COMMERCE</h5>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">

                                <form method="post" className="form-group text-center" onSubmit={(e)=>onFormSubmit(e,'ecom')}>

                                    <div className="formCont">
                                    <input type="url" onChange={(e)=>setEcomUrl(e.target.value)} className="form-control" placeholder={`Enter Product URL`} />
                                    </div>

                                    <div className="groupbtn">
                                    <button type="button"  onClick={(e)=>setEcom(false)}className="demoLink" disabled={loader}>
                                       Cancel
                                    </button>
                                    <button type="submit" className="demoLink" disabled={loader}>
                                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Create
                                    </button>

                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardHead;